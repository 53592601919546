<template>
  <div class="container" :class="{'is-ready': state.isReady}"
       v-if="!state.isFailed">
    <section class="section section__stickers">
      <h2 class="section__title">Shape</h2>
      <div class="shape__wrapper">
        <stickers-module/>
      </div>
    </section>
    <section class="section section__profile">
      <h2 class="section__title">Design</h2>
      <div class="design__wrapper">
        <div class="profile-card">
          <div class="profile-card__figure">
            <camera-module class="camera"/>
            <decorator-module class="decorator"/>
          </div>
          <div class="profile-card__profile">
            <qrcode class="qrcode" :value="`${host}/gotoportal`" :size="100" level="L" foreground="#ffffff"
                    background="transparent"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section section__controller">
      <h2 class="section__title">Create</h2>
      <div class="create__wrapper">
        <controller-module/>
      </div>
    </section>
  </div>
  <div v-if="state.isFailed" class="notification">
    <div class="notification__container">
      <h1>
        <span>Create your own</span><br>
        <mark>#</mark>
        <span>mySDCstack!</span>
      </h1>
      <p class="notification__description">In order to use our service, please allow camera access.</p>
      <router-link class="notification__button" to="/">Go Back</router-link>
    </div>
    <!--template v-if="!state.isFailed">
      카메라를 허용해주세요.
    </template>
    <template v-else>
      카메라를 허용해야 이용하실 수 있습니다.
    </template-->
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, onUnmounted, reactive} from 'vue'
import stickersModule from '@/modules/stickers'
import cameraModule from '@/modules/camera'
import decoratorModule from '@/modules/decorator'
import controllerModule from '@/modules/controller'
import qrcode from "@/components/qrcode";
import uuid from 'short-uuid'

const useEmitter = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance.appContext.config.globalProperties.emitter;
}

export default {
  components: {
    stickersModule,
    cameraModule,
    decoratorModule,
    controllerModule,
    qrcode
  },
  setup() {
    let host = location.origin
    let id = uuid.generate()
    const state = reactive({
      isReady: false,
      isFailed: false
    })
    const emitter = useEmitter()
    const methods = {
      shoot: () => {
        // console.log('shoot:create')
        emitter.emit('take:camera', {url: `${host}/${id}`, id})
      }
    }
    const resizer = () => {
      document.documentElement.style.setProperty('--viewport-height', window.innerHeight + 'px')
    }
    onMounted(() => {
      emitter.on('shoot', methods.shoot)
      emitter.on('initialized:camera', () => {
        state.isReady = true
      })
      emitter.on('failed:camera', () => {
        state.isFailed = true
      })
      // window.addEventListener('resize', resizer)
      resizer()
    })
    onUnmounted(() => {
      emitter.off('shoot')
      emitter.off('initialized:camera')
      emitter.off('failed:camera')
      window.removeEventListener('resize', resizer)
    })
    return {
      host,
      id,
      methods,
      emitter,
      state,
      resizer
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //visibility: hidden;
  //position: absolute;
  //top: -999em;

  &.is-ready {
    height: auto;
    min-height: var(--viewport-height);
    visibility: visible;
    overflow: auto;
    position: static;
  }

  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
}

.section {
  position: relative;
  margin-top: 24px;

  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }

  &__title {
    font-size: 100px;
    font-family: 'SamsungSharpSans', sans-serif;
    font-weight: bold;
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
    line-height: 1.1;
    color: #000;
    opacity: 0.1;
    padding-top: 0.1em;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &__stickers {
    height: 124px;
    @media only screen and (max-width: 600px) {
      position: absolute;
      top: calc(100vw + 24px);
      width: 100%;
    }
  }

  &__profile #{&}__title {
    right: 24px;
    left: auto;
  }

  &__controller {
    height: 104px;
    margin: 24px 0;
    @media only screen and (max-width: 600px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      height: 116px;
      padding: 24px 0;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.shape, .design, .create {
  &__wrapper {
    width: 560px;
    height: 100%;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.profile-card {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: $primary-color;
  position: relative;

  &__figure {
    position: absolute;
    width: 100%;
    height: 75%;
    top: 0;

    .camera, .decorator {
      position: absolute;
      width: 100%;
    }
  }

  &__profile {
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
    padding: 4%;
    box-sizing: border-box;

    .qrcode {
      position: absolute;
      width: 17% !important;
      height: auto !important;
      top: 50%;
      right: 4%;
      transform: translate(0, -50%);
    }
  }
}

.notification {
  width: 100%;
  height: var(--viewport-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;

  &__container {
    height: 100%;
    background-image: url("~@/assets/camera_access_bg-pc.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      background-image: url("~@/assets/camera_access_bg-mo.jpg");
    }

    h1 {
      font-family: 'SamsungSharpSans', sans-serif;
      font-size: 40px;
      font-weight: bold;
      line-height: 1.2;
      color: #000;
      padding: 0 32px;

      mark {
        background: none;
        color: #00b3e3;
      }

      @media only screen and (max-width: 600px) {
        font-size: 28px;
      }
    }
  }

  &__description {
    margin: 32px 0;
    font-family: 'SamsungOne';
    font-size: 18px;
    line-height: 1.56;
    text-align: center;
    color: #000;
    @media only screen and (max-width: 600px) {
      margin: 16px 56px;
    }
  }

  &__button {
    width: 99px;
    height: 40px;
    margin: 0 auto;
    padding: 10px 24px;
    border-radius: 20px;
    background-color: #000;
    font-family: 'SamsungOne';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #fff;
  }
}
</style>
