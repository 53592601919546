<template>
  <div class="decorator">
    <div ref="wrapperElement" class="decorator__canvas-wrap">
      <canvas id="stickerCanvas" class="decorator__canvas"></canvas>
      <canvas ref="dummyCanvas" class="decorator__canvas" style="pointer-events: none"></canvas>
    </div>
  </div>
  <!--img :src="data.imageUrl" width="200" height="150" style="border: 2px solid red; position: absolute; width:200px; height:150px; left: 50%; top: 50%; transform: translate(-50%, -50%);background-color:#fff;"/-->
</template>

<script>
import {computed, getCurrentInstance, onMounted, onUnmounted, reactive, ref} from 'vue'
import {useStore} from '@/helper/vue'
import {fabric} from 'fabric'
import lottie from 'lottie-web'
import router from "@/router";
import {pick, remove} from 'lodash'

const useEmitter = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance.appContext.config.globalProperties.emitter;
}

fabric.AEAnimation = fabric.util.createClass(fabric.Image, {
  type: 'croppableimage',
  initialize: function (AECanvas, options) {
    options = options || {}
    this.callSuper('initialize', AECanvas, options)
    this._AECanvas = AECanvas
  },
  drawCacheOnCanvas: function (ctx) {
    ctx.drawImage(this._AECanvas, -this.width / 2, -this.height / 2);
  },
  _createCacheCanvas: function () {
    this._cacheCanvas = this._AECanvas;
    this.dirty = true;
  },
  render: function (ctx) {
    if (this.isNotVisible()) {
      return;
    }
    if (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen()) {
      return;
    }
    ctx.save();
    this._setupCompositeOperation(ctx);
    this.drawSelectionBackground(ctx);
    this.transform(ctx);
    this._setOpacity(ctx);
    this._setShadow(ctx, this);
    if (this.transformMatrix) {
      ctx.transform.apply(ctx, this.transformMatrix);
    }
    this.clipTo && fabric.util.clipContext(this, ctx);
    if (this.shouldCache()) {
      if (!this._cacheCanvas) {
        this._createCacheCanvas();
      }
      this.drawCacheOnCanvas(ctx);
    } else {
      // console.log('remove cache and draw')
      this.drawObject(ctx);
      if (this.objectCaching && this.statefullCache) {
        this.saveState({propertySet: 'cacheProperties'});
      }
    }
    this.clipTo && ctx.restore();
    ctx.restore();
  }
})

const stickerFields = ['uid', 'file', 'width', 'height', 'isAnimation', 'left', 'top', 'scaleX', 'scaleY', 'stickerId', 'zoomX', 'zoomY', 'angle', 'adjustScale']

export default {
  setup() {
    const {commit, state} = useStore()
    const wrapperElement = ref(null)
    const dummyCanvas = ref(null)
    const data = reactive({
      fabricCanvas: computed(() => new fabric.Canvas('stickerCanvas')),
      adjustScale: 1,
      stickersInfo: [],
      userImage: null,
      facingMode: true,
      deleteState: false,
      currentStickers: [],
      animStickers: [],
      imageUrl: '',
      takeImage: computed(() => state.takeImage),
      done: false
    })
    const emitter = useEmitter()
    const methods = {
      drawStickers: (sticker) => {
        const uid = new Date().getTime()
        if (sticker.isAnimation) {
          const canvas = document.createElement('canvas')
          canvas.width = 2400;
          canvas.height = 2400;
          const jsonData = require(`@/assets/stickers/${sticker.file.replace('.svg', '.json')}`)
          const loader = lottie.loadAnimation({
            renderer: 'canvas',
            loop: true,
            autoplay: true,
            animationData: jsonData,
            rendererSettings: {
              context: canvas.getContext('2d'),
              preserveAspectRatio: 'xMidYMid meet',
              clearCanvas: true
            }
          })
          loader.addEventListener('DOMLoaded', function () {
            const fabricImage = new fabric.AEAnimation(canvas, {
              scaleX: 0.06 * data.adjustScale,
              scaleY: 0.06 * data.adjustScale,
              objectCaching: true
            })
            fabricImage.uid = uid
            fabricImage.storeIndex = data.fabricCanvas._objects.length;
            fabricImage.stickerId = sticker.id;
            fabricImage.isAnimation = true;
            fabricImage.padding = -10;
            fabricImage.file = sticker.file
            fabricImage.left = sticker.left || (data.fabricCanvas.width - (fabricImage.width * fabricImage.scaleX)) / 2
            fabricImage.top = sticker.top || (data.fabricCanvas.height - (fabricImage.height * fabricImage.scaleY)) / 2
            fabricImage.adjustScale = data.adjustScale
            data.fabricCanvas.add(fabricImage)
            data.fabricCanvas.bringToFront(fabricImage);
            commit('addSticker', pick(fabricImage, stickerFields));
            loader.uid = uid
            data.animStickers.push(loader)
          })
          loader.addEventListener('enterFrame', (e) => {
            data.fabricCanvas.requestRenderAll()
          })
        } else {
          fabric.loadSVGFromURL(require(`@/assets/stickers/${sticker.file}`), function (objects, options) {
            const fabricImage = fabric.util.groupSVGElements(objects, options);
            const scale = 80 / fabricImage.width * data.adjustScale
            fabricImage.uid = uid
            fabricImage.storeIndex = data.fabricCanvas._objects.length;
            fabricImage.stickerId = sticker.id;
            fabricImage.padding = 7
            fabricImage.isAnimation = false;
            fabricImage.file = sticker.file
            // fabricImage.scaleX /= data.adjustScale
            // fabricImage.scaleY /= data.adjustScale
            fabricImage.left = (sticker.left || (data.fabricCanvas.width - (fabricImage.width * scale)) / 2)
            fabricImage.top = (sticker.top || (data.fabricCanvas.height - (fabricImage.height * scale)) / 2)
            fabricImage.adjustScale = data.adjustScale
            data.fabricCanvas.add(fabricImage.scale(sticker.scale || scale)).setActiveObject(fabricImage).renderAll();
            data.fabricCanvas.bringToFront(fabricImage);
            commit('addSticker', pick(fabricImage, stickerFields));
          });
        }
      },
      transformSticker: (options) => {
        const obj = options.target
        const targetIdx = state.currentStickers.findIndex(item => item.uid === obj.uid)
        commit('transformSticker', {
          index: targetIdx > -1 ? targetIdx : obj.storeIndex, sticker: pick(obj, stickerFields)
        })
        data.fabricCanvas.bringToFront(obj);
      },
      removeSticker: (e) => {
        const targetIdx = state.currentStickers.findIndex(item => item.uid === e.target.uid)
        if (targetIdx > -1) {
          commit('removeSticker', targetIdx)
        }
        remove(data.animStickers, (item) => {
          if (item.uid === e.target.uid) {
            item.destroy()
            return true
          }
          return false
        })
      },
      clearStickers: () => {
        data.fabricCanvas.remove(...data.fabricCanvas.getObjects())
        commit('clearSticker')
      },
      take: async (payload) => {
        // console.log('shoot:decorator', payload)
        if (data.done) {
          return
        }
        data.done = true

        data.animStickers.forEach((loader) => loader.goToAndStop(loader.totalFrames - 1, true))
        data.fabricCanvas.discardActiveObject().renderAll();
        data.fabricCanvas.requestRenderAll()

        const size = {
          width: 600,
          height: 450
        }

        dummyCanvas.value.setAttribute("width", size.width)
        dummyCanvas.value.setAttribute("height", size.height)

        const dummyContext = dummyCanvas.value.getContext("2d")
        const videoEl = payload.videoEl.value
        const blob = {}
        const toBlob = (canvasEl) => {
          return new Promise(resolve => canvasEl.toBlob(resolve))
        }

        let width = videoEl.videoWidth
        let height = videoEl.videoHeight
        let offsetX = 0
        let offsetY = 0
        let scale = 1
        const targetRatio = size.width / size.height
        const sourceRatio = width / height

        if (targetRatio > sourceRatio) {
          scale = size.width / width
          offsetY = (height - (size.height / scale)) / 2
          height = width / targetRatio
        } else {
          scale = size.height / height
          offsetX = (width - (size.width / scale)) / 2
          width = height * targetRatio
        }

        // console.log(videoEl.videoWidth, offsetX * scale, offsetY * scale, width * scale, height * scale, 0, 0, size.width, size.height)

        dummyContext.drawImage(videoEl, offsetX, offsetY, width, height, 0, 0, size.width, size.height)

        await toBlob(dummyCanvas.value).then(data => {
          blob.origin = data //window.URL.createObjectURL(data)
        })
        dummyContext.drawImage(document.getElementById('stickerCanvas'), 0, 0, size.width, size.height)
        await toBlob(dummyCanvas.value).then(data => {
          blob.decorated = data //window.URL.createObjectURL(data)
        })
        commit('takeImage', blob)
        await router.push({name: 'registration', params: payload})
      },
      initOverlay: () => {
        wrapperElement.value && data.fabricCanvas.setOverlayImage(require('@/assets/sdc-logo.webp'), () => {
          const canvas = data.fabricCanvas

          data.adjustScale = canvas.width / 560

          if (wrapperElement.value && canvas.width !== wrapperElement.value.offsetWidth) initializeCanvas()

          canvas.overlayImage.top = 23 * (canvas.height / 420);
          canvas.overlayImage.left = canvas.width - ((24 + canvas.overlayImage.width / 3) * (canvas.width / 560))
          canvas.overlayImage.scaleX = 1 / 3 * (canvas.width / 560);
          canvas.overlayImage.scaleY = 1 / 3 * (canvas.height / 420);
          canvas.renderAll();
        })
      }
    }

    const setFabricControl = () => {
      // 스티커 컨트롤 디자인 변경
      const fabricObject = fabric.Object.prototype;
      const img = document.createElement('img');
      img.src = require('@/assets/cancel.svg');

      const scale = document.getElementById('stickerCanvas').offsetWidth / 560

      fabricObject.setControlsVisibility({mt: false, mb: false, ml: false, mr: false});

      fabricObject.transparentCorners = false;
      fabricObject.borderColor = 'black';
      fabricObject.cornerColor = '#1428a0';
      fabricObject.cornerStyle = 'circle';
      fabricObject.cornerSize = 12 * scale
      fabricObject.padding = 7 * scale
      fabricObject.centeredScaling = true
      fabricObject.controls.mtr.offsetY = -70 * scale
      fabricObject.controls.deleteControl = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 30 * scale,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon,
        cornerSize: 32 * scale
      });

      function deleteObject(eventData, transform) {
        const target = transform.target;
        const canvas = target.canvas;
        canvas.remove(target);
        canvas.requestRenderAll();
        // console.log(target)
        // remove(data.animStickers, (item) => {
        //   if (item.uid === target.uid) {
        //     console.log(item)
        //     return true
        //   }
        //   return false
        // })
      }

      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        // console.log("this", this);
        // console.log("fabricObject", fabricObject);
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);
        ctx.restore();
      }
    }
    const initializeCanvas = () => {
      wrapperElement.value && data.fabricCanvas
        .setDimensions({width: wrapperElement.value.offsetWidth, height: wrapperElement.value.offsetHeight})
        .on({
          'object:moved': methods.transformSticker,
          'object:scaled': methods.transformSticker,
          'object:rotated': methods.transformSticker,
          'object:removed': methods.removeSticker
        })
      methods.initOverlay()
      setFabricControl();
    }
    onMounted(() => {
      initializeCanvas();
      emitter.on('addSticker', methods.drawStickers)
      emitter.on('clearSticker', methods.clearStickers)
      emitter.on('take:decorator', methods.take)
      window.addEventListener('resize', methods.initOverlay)
    })
    onUnmounted(() => {
      emitter.off('addSticker')
      emitter.off('clearSticker')
      emitter.off('take:decorator')
      window.removeEventListener('resize', methods.initOverlay)
    })
    return {
      data,
      methods,
      wrapperElement,
      dummyCanvas
    }
  }
}
</script>

<style lang="scss" scoped>
.decorator {
  > img {
    position: absolute;
    left: 30px;
    top: 30px;
    border: 1px solid black;
  }

  &__canvas {
    width: 560px;
    height: 420px;
    left: 0;
    //object-fit: cover;
    position: absolute;

    &-wrap {
      width: 100%;
      padding-bottom: 75%;
      position: relative;

      ::v-deep .canvas-container {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100vw;
      height: 75vw;
    }
  }
}
</style>
