<template>
  <div class="controller">
    <button @click="methods.confirmClear" :disabled="(data.stickerLength<1)"
            class="controller__button controller__button--clear">
      <img src="@/assets/clear.svg" alt="Clear stickers"/>
    </button>
    <button @click="methods.shoot" class="controller__button controller__button--shoot" :disabled="!data.initialized || data.done">
      <img src="@/assets/shutter.svg" alt="Take a picture"/>
    </button>
    <button @click="methods.switchCamera" :disabled="data.isSingleDevice"
            class="controller__button controller__button--switch">
      <img src="@/assets/switch.svg" alt="Switch camera"/>
    </button>
  </div>
  <modal v-if="data.openClearModal" @close="data.openClearModal = false">
    <template v-slot:body>Do you want to clear all the stickers?</template>
    <template v-slot:buttons>
      <button @click="data.openClearModal = false">No</button>
      <button @click="methods.clearSticker" class="button--confirm">Yes</button>
    </template>
  </modal>
</template>

<script>
import {filter} from 'lodash'
import {computed, getCurrentInstance, onMounted, reactive} from "vue";
import {useStore} from '@/helper/vue'
import modal from '@/modules/modal.vue'

const useEmitter = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance.appContext.config.globalProperties.emitter;
}

export default {
  components: {modal},
  setup() {
    const {state} = useStore()
    const data = reactive({
      openClearModal: false,
      stickerLength: computed(() => state.currentStickers.length),
      initialized: false,
      isSingleDevice: true,
      done: false
    })
    const emitter = useEmitter()
    const methods = {
      confirmClear: () => {
        data.openClearModal = true
      },
      clearSticker: () => {
        data.openClearModal = false
        emitter.emit('clearSticker')
      },
      shoot: () => {
        // console.log('shoot:controller')
        if (!data.done) {
          emitter.emit('shoot')
          data.done = true
        }
      },
      switchCamera: () => {
        emitter.emit('switchCamera')
      },
      checkDevice: () => {
        data.initialized = true
        navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
          data.isSingleDevice = !((filter(deviceInfos, {kind: 'videoinput'})).length > 1)
        }).catch(error => {
          console.log("error", error);
        });
      }
    }
    onMounted(() => {
      emitter.on('initialized:camera', methods.checkDevice)
    })
    return {
      data,
      methods,
      emitter
    }
  }
}
</script>

<style lang="scss" scoped>
.controller {
  height: 100%;
  position: relative;

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);

    &--clear, &--switch {
      width: 48px;
      height: 48px;
      border: 1.5px solid #000;
      border-radius: 24px;

      img {
        width: auto;
        height: 24px;
      }

      &[disabled] {
        opacity: 0.3;
      }
    }

    &--clear {
      margin-left: -113px;
    }

    &--shoot {
      left: 50%;
      width: 72px;
      height: 72px;
      border: 2px solid #7f92da;
      border-radius: 36px;
      background-color: $primary-color;

      img {
        width: 32px;
        height: 32px;
      }

      &[disabled] {
        opacity: 0.5
      }
    }

    &--switch {
      margin-left: 113px;
    }
  }
}
</style>
