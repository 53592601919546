<template>
  <div class="stickers">
    <template v-for="(stickers, tabIndex) in data.stickers" :key="tabIndex">
      <button class="stickers__tab" @click="methods.changeTab(tabIndex)"
              :class="{'stickers__tab--active': data.currentTab === tabIndex}">{{ stickers.group }}
      </button>
      <div class="stickers__panel" v-show="data.currentTab === tabIndex">
        <div class="stickers__container">
          <swiper :navigation="{prevEl:`.slide__button--prev-${tabIndex}`, nextEl:`.slide__button--next-${tabIndex}`}"
                  @swiper="swiper => { carousel[tabIndex] = swiper }"
                  :allow-touch-move="false"
                  :simulate-touch="false"
                  :slides-per-view="6.5"
                  :slides-per-group="6" class="slide">
            <swiper-slide v-for="(sticker, index) in stickers.item.filter(_item => !_item.ignore)" :key="index" class="slide__item">
              <button @click.passive="methods.addSticker(sticker)">
                <img :src="require(`@/assets/stickers/${sticker.file}`)" alt="">
              </button>
            </swiper-slide>
          </swiper>
          <div class="list__wrap">
            <div v-for="(sticker, index) in stickers.item.filter(_item => !_item.ignore)" :key="index" class="list__item">
              <button @click.passive="methods.addSticker(sticker)">
                <img :src="require(`@/assets/stickers/${sticker.file}`)" alt="">
              </button>
            </div>
          </div>
        </div>
        <button :class="`slide__button slide__button--prev slide__button--prev-${tabIndex}`">
          <img src="@/assets/arrow.svg" alt="" class="slide__arrow slide__arrow--prev">
        </button>
        <button :class="`slide__button slide__button--next slide__button--next-${tabIndex}`">
          <img src="@/assets/arrow.svg" alt="" class="slide__arrow slide__arrow--next">
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import AnimateSvg from '@/components/AnimateSvg.vue'
import {computed, getCurrentInstance, nextTick, onBeforeUpdate, reactive} from 'vue'
import {useStore} from '@/helper/vue'
import SwiperCore, {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);

const addAnimation = (id, isAnimate) => {
  fabric.Sprite = fabric.util.createClass(fabric.Image, {
    type: 'sprite',
    spriteWidth: 50,
    spriteHeight: 72,
    spriteIndex: 0,
    frameTime: 100,

    initialize: function (element, options) {
      options || (options = {});

      options.width = this.spriteWidth;
      options.height = this.spriteHeight;

      this.callSuper('initialize', element, options);

      this.createTmpCanvas();
      this.createSpriteImages();
    },

    createTmpCanvas: function () {
      this.tmpCanvasEl = fabric.util.createCanvasElement();
      this.tmpCanvasEl.width = this.spriteWidth || this.width;
      this.tmpCanvasEl.height = this.spriteHeight || this.height;
    },

    createSpriteImages: function () {
      this.spriteImages = [];

      var steps = this._element.width / this.spriteWidth;
      for (var i = 0; i < steps; i++) {
        this.createSpriteImage(i);
      }
    },

    createSpriteImage: function (i) {
      var tmpCtx = this.tmpCanvasEl.getContext('2d');
      tmpCtx.clearRect(0, 0, this.tmpCanvasEl.width, this.tmpCanvasEl.height);
      tmpCtx.drawImage(this._element, -i * this.spriteWidth, 0);

      var dataURL = this.tmpCanvasEl.toDataURL('image/png');
      var tmpImg = fabric.util.createImage();

      tmpImg.src = dataURL;

      this.spriteImages.push(tmpImg);
    },

    _render: function (ctx) {
      ctx.drawImage(
        this.spriteImages[this.spriteIndex],
        -this.width / 2,
        -this.height / 2
      );
    },

    play: function () {
      var _this = this;
      this.animInterval = setInterval(function () {
        _this.onPlay && _this.onPlay();
        _this.dirty = true;
        _this.spriteIndex++;
        if (_this.spriteIndex === _this.spriteImages.length) {
          _this.spriteIndex = 0;
        }
      }, this.frameTime);
    },

    stop: function () {
      clearInterval(this.animInterval);
    }
  });

  fabric.Sprite.fromURL = function (url, callback, imgOptions) {
    fabric.util.loadImage(url, function (img) {
      callback(new fabric.Sprite(img, imgOptions));
    });
  };

  fabric.Sprite.async = true;

  //////////////////////////////////////////////////////////////

  fabric.Sprite.fromURL(require(`@/assets/stickers/sticker-${id}.png`), function (sprite) {
    sprite['stickerId'] = id;
    sprite['isAnimate'] = isAnimate;
    data.fabricCanvas.add(sprite);
    sprite.set('dirty', true);
    sprite.play();
  });

  (function render() {
    data.fabricCanvas.renderAll();
    fabric.util.requestAnimFrame(render);
  })();
}

const useEmitter = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance.appContext.config.globalProperties.emitter;
}

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AnimateSvg,
    Swiper,
    SwiperSlide
  },
  setup(props) {
    const {state, commit, dispatch} = useStore()
    let carousel = []
    const data = reactive({
      stickers: computed(() => state.stickers),
      currentTab: 0
    })
    const emitter = useEmitter()
    const methods = {
      addSticker: (sticker) => {
        emitter.emit('addSticker', {uid: new Date().getTime(), ...sticker})
      },
      changeTab: (index) => {
        data.currentTab = index
        nextTick(() => {
          carousel[index].update()
        })
      }
    }
    onBeforeUpdate(() => {
      carousel.value = [];
    })
    return {
      data,
      methods,
      carousel
    }
  }
}
</script>

<style lang="scss" scoped>
.stickers {
  position: relative;

  &__wrap {
    display: flex;
  }

  &__tab {
    font-family: 'SamsungSharpSans';
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    padding: 8px 12px 4px;
    border-radius: 16px;
    margin: 0 12px;

    &--active {
      color: #fff;
      background-color: #000000;
    }

    @media only screen and (max-width: 600px) {
      margin: 0 2vw;
    }
  }

  &__panel {
    margin-top: 14px;
    position: absolute;
    width: 100%;
    @media only screen and (max-width: 600px) {
      width: auto;
      margin-top: 2.6666vw;
      left: 4.266vw;
      right: 4.266vw;
      margin-bottom: 42.6vw;
    }
  }

  &__container {
    border-radius: 8px;
    background-color: #f5f5f5;
    @media only screen and (max-width: 600px) {
      margin-bottom: 116px;
      overflow: auto;
      height: 44.8vw;
    }
  }
}

.list__wrap {
  width: 87.4664vw;
  display: none;
  flex-wrap: wrap;
  margin: 2vw auto;
  @media only screen and (max-width: 600px) {
    display: flex;
  }
}

.list__item {
  img {
    width: 15.4666vw;
    height: 15.4666vw;
    object-fit: contain;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    margin: 3.2vw;
  }
}

.slide {
  @media only screen and (max-width: 600px) {
    display: none;
  }

  &__item {
    width: 80px !important;
    height: 80px;
    padding: 16px;
    margin: 3px;

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
  }

  &__arrow {
    width: 32px;
    height: 32px;

    &--prev {
      transform: rotateY(180deg);
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;

    &--prev {
      left: -36px;
    }

    &--next {
      right: -36px;
    }

    &[disabled] {
      opacity: 0.3;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}
</style>
