<template>
  <div class="camera">
    <div class="camera__video-wrap">
      <video ref="videoElement" class="camera__video" playsinline autoplay muted/>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref} from "vue";

const useEmitter = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance.appContext.config.globalProperties.emitter;
}

export default {
  component: {},
  setup() {
    const videoElement = ref(null)
    let streamInstance = ref(null)

    const data = reactive({
      facingMode: true,
      cameraInitialized: false,
      currentWidth: 0,
      tryCount: 0,
      videoSource: [],
      selectSource: null
    })
    const emitter = useEmitter()

    const gotDevices = (deviceInfos) => {
      deviceInfos.forEach(deviceInfo => {
        if (deviceInfo.kind === 'videoinput') {
          data.videoSource.push(deviceInfo)
        }
        data.selectSource = data.videoSource[0]
      })
    }

    const gotStream = (stream) => {
      window.stream = stream
      videoElement.value.srcObject = stream
      data.cameraInitialized = true
      emitter.emit('initialized:camera')
      return navigator.mediaDevices.enumerateDevices()
    }

    const methods = {
      playVideo: async (switching) => {
        if (window.stream) {
          window.stream.getTracks().forEach(track => {
            track.stop()
          })
        }

        const width = window.innerWidth
        if (width === data.currentWidth && !switching) return

        const constraints = {
          video: {
            width: width,
            height: width * 0.75,
            aspectRatio: !window.orientation ? 0.75 : 1.3333,
            resizeMode: 'crop-and-scale',
            facingMode: data.facingMode ? 'user' : 'environment'
          }
        }
        data.currentWidth = width

        navigator.mediaDevices.getUserMedia(constraints).then(gotStream).then(gotDevices).catch((error) => {
          emitter.emit('failed:camera', error)
        })
      },
      stopVideo: () => {
        videoElement.value.pause()
        return Promise.resolve()
      },
      switchCamera: () => {
        data.facingMode = !data.facingMode;
        methods.playVideo(true);
      },
      take: (payload) => {
        // console.log('shoot:camera')
        methods.stopVideo().then(() => {
          emitter.emit('take:decorator', {videoEl: videoElement, ...payload})
        })
      }
    }

    onMounted(() => {
      methods.playVideo()
      emitter.on('switchCamera', methods.switchCamera)
      emitter.on('take:camera', methods.take)
      window.addEventListener('resize', methods.playVideo(false))
    })

    onBeforeUnmount(async () => {
      await methods.stopVideo()
      try {
        if (window.stream) {
          window.stream.getTracks().forEach(track => {
            track.stop()
          })
        }
      } catch (e) {
        // console.error(e)
      }

      emitter.off('switchCamera')
      emitter.off('take:camera')
      window.removeEventListener('resize', methods.playVideo(false))
    })

    return {
      data,
      methods,
      videoElement
    }
  }
}
</script>

<style lang="scss" scoped>
.camera {
  background-color: rgba(255, 255, 255, 0.5);

  &__video {
    width: 560px;
    height: 420px;
    left: 0;
    object-fit: cover;
    position: absolute;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    &-wrap {
      width: 100%;
      padding-bottom: 75%;
      position: relative;
    }

    @media only screen and (max-width: 600px) {
      width: 100vw;
      height: 75vw;
    }
  }
}
</style>
